<!-- 人力资源 -->
<template>
  <div>
    <div class="header">
      <div>
        <h3>{{ text5 }}</h3>
      </div>
    </div>
    <div class="container">
      <h2>{{ text6 }}</h2>
      <div class="container_top">
        <div class="top_box">
          <img src="https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/renli/renli_1.png" alt="" />
          <div>
            <h3>{{ text1 }}</h3>
            <p>
              {{ text2 }}
            </p>
          </div>
        </div>
      </div>
      <div class="container_bottom">
        <div class="bottom_box">
          <div>
            <h3>{{ text3 }}</h3>
            <p>
              {{ text4 }}
            </p>
          </div>
          <img src="https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/renli/renli_2.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let language = localStorage.getItem("language") || "cn";
import { mapState } from "vuex";
export default {
  data() {
    switch (language) {
      case "cn":
        return {
          text1: "公司运动会",
          text2: "在趣味游戏及比赛中加深友谊,展现团结协作、活跃奋进的精神,增强团队凝聚力。",
          text3: "春秋季出游",
          text4: "使员工远离城市的喧嚣,领略大自然的美景,放松心情、愉悦身心,以更好的精神状态投入到今后的工作及生活中去。",
          text5: "职业发展",
          text6: "同心同力 共创明济",
        };
        break;
      case "en":
        return {
          text1: "Team Building Activities",
          text2:
            "Team building activities are held regularly to strengthen relationship and deepen friendship through fun games and competitions to demonstrate teamwork and cooperation, and striving spirit to facilitate team bonding. ",
          text3: "Spring and Fall Outings ",
          text4: "Company outings are held to facilitate team bonding, create new ideas and reduce workplace stress.",
          text5: "Careers",
          text6: "Together for a Shared FutureGen",
        };
        break;
    }
  },
  computed: {
    ...mapState(["isLanguage"]),
  },
};
</script>

<style lang="less" scoped>
body {
  overflow-x: hidden;
  font-family: Arial, Helvetica, sans-serif, "microsoft yahei", "pingfang sc";
  background: white;
}
.header {
  width: 100%;
  height: 400px;
  background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/renli/header.png") no-repeat;
  background-size: 100% 100%;
  div {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    h3 {
      position: relative;
      font-size: 50px;
      color: #fff;
      padding-top: 20%;
    }
    h3::after {
      position: absolute;
      top: 110%;
      left: 0%;
      content: "";
      width: 50px;
      height: 2px;
      background-color: #fff;
    }
  }
}
.container {
  h2 {
    position: relative;
    text-align: center;
    color: rgba(32, 56, 100, 1);
    font-size: 35px;
    font-weight: normal;
    margin: 10vh 0;
  }
  h2::after {
    content: "";
    position: absolute;
    bottom: -30px;
    left: 50%;
    width: 50px;
    height: 2px;
    background-color: rgba(32, 56, 100, 1);
    transform: translateX(-50%);
  }
  .container_top {
    width: 100%;
    color: #fff;
    background-color: #203864;
    .top_box {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 600px;
        height: 420px;
        border: none;
      }
      div {
        width: 600px;
        opacity: 0.8;
        margin-left: 40px;
        h3 {
          position: relative;
          font-size: 30px;
          margin-bottom: 40px;
        }
        h3::after {
          position: absolute;
          bottom: -50%;
          left: 0%;
          content: "";
          width: 555px;
          height: 1px;
          background-color: rgba(204, 204, 204, 0.568);
        }
        p {
          font-size: 20px;
          opacity: 0.6;
        }
      }
    }
  }
  .container_bottom {
    width: 100%;
    background-color: rgba(204, 204, 204, 0.2);
    .bottom_box {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        width: 45%;
        opacity: 0.8;
        h3 {
          position: relative;
          color: #203864;
          font-size: 30px;
          margin-bottom: 40px;
        }
        h3::after {
          position: absolute;
          bottom: -50%;
          left: 0%;
          content: "";
          width: 540px;
          height: 1px;
          background-color: rgba(204, 204, 204, 0.568);
        }
        p {
          font-size: 20px;
          opacity: 0.6;
        }
      }
      img {
        width: 600px;
        height: 420px;
        border: none;
        background-color: skyblue;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .header {
    height: 150px;
    div {
      h3 {
        font-size: 24px;
        margin-left: 10%;
        font-weight: normal;
        padding-top: 20%;
      }
      h3::after {
        width: 30px;
      }
    }
  }
  .container {
    h2 {
      font-size: 24px;
      margin: 5vh 0;
    }
    h2:after {
      bottom: -20px;
    }
    .container_top {
      .top_box {
        flex-direction: column;
        padding-bottom: 20px;
        img {
          margin-top: 40px;
          width: 85vw;
          height: 30vh;
        }
        div {
          width: 90vw;
          margin-left: 0px;
          h3 {
            font-size: 24px;
            margin-top: 12px;
            margin-bottom: 16px;
          }
          h3::after {
            display: none;
          }
          p {
            font-size: 18px;
          }
        }
      }
    }
    .container_bottom {
      .bottom_box {
        flex-direction: column;
        padding-bottom: 20px;
        div {
          width: 92%;
          h3 {
            font-size: 26px;
            margin-top: 10px;
            margin-bottom: 10px;
          }
          h3::after {
            display: none;
          }
          p {
            font-size: 18px;
          }
        }
        img {
          width: 92vw;
          height: 220px;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
